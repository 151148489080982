import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '../components/sideBar';
import { getCategories } from '../apiService';
import EditCategoryModal from '../components/editCategoryModal';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  button {
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
  }

  button:hover {
    background-color: #45a049;
  }
`;

function ManageCategories() {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // Fetch categories when the component mounts
    const fetchCategories = async () => {
      try {
        // Retrieve the token from local storage
        const authToken = localStorage.getItem('authToken');

        // Make API request to fetch categories with the token
        const response = await getCategories(authToken);

        // Assuming the API response contains a 'data' field with categories
        setCategories(response.data);
      } catch (error) {
        // Handle error
        setError('Failed to fetch categories. Please try again.');
      }
    };

    fetchCategories();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  // Function to handle opening the edit modal
  const handleEditClick = (category) => {
    setSelectedCategory(category);
    setIsEditModalOpen(true);
  };

  // Function to handle saving changes from the edit modal
  const handleSaveEdit = (newName) => {
    // Perform the logic to update the category on the server
    // ...

    // Update the categories state with the new data
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category._id === selectedCategory._id
          ? { ...category, name: newName }
          : category
      )
    );
  };

  return (
    <Wrapper>
      <Sidebar />
      <ContentContainer>
        <h2>Manage App Categories</h2>
        {error && <p>{error}</p>}
        <table>
          <thead>
            <tr>
              <th>Category ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category._id}>
                <td>{category._id}</td>
                <td>{category.name}</td>
                <td>
                  <button onClick={() => handleEditClick(category)}>Edit</button>
                  {/* Add the delete button and its logic here */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <EditCategoryModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={handleSaveEdit}
          categoryName={selectedCategory?.name || ''}
        />
      </ContentContainer>
    </Wrapper>
  );
}

export default ManageCategories;
