import React, {useState} from 'react';
import styled from 'styled-components';
import Sidebar from '../components/sideBar';
import {exportChat, searchChat} from "../apiService";
import {toast} from "react-toastify";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const Wrapper = styled.div`
    display: flex;
    height: 100vh;
`;

const ContentContainer = styled.div`
    flex: 1;
    padding: 20px;

    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }

    th,
    td {
        border: 1px solid #ddd;
        padding: 12px;
        text-align: left;
    }

    th {
        background-color: #f2f2f2;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    button {
        cursor: pointer;
        background-color: #4caf50;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    button.danger {
        cursor: pointer;
        background-color: #FF0000;
    }

    button:hover {
        background-color: #45a049;
    }

    button:hover.danger {
        cursor: pointer;
        background-color: #AA0000;
    }
`;

function Exports() {
    const [requestBecomeCreators, setRequestBecomeCreators] = useState([]);
    //const [countries, setCountries] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [chatInfo, setChatInfo] = useState(null);

    const handleExportChat = async (chatId) => {
        try {
            const authToken = localStorage.getItem('authToken');
            await exportChat(authToken, chatId);
            // Update the user's status locally in the table

        } catch (error) {
            console.error('Error export user:', error);
        }
    };

    const handleSearch = async (query) => {
        setIsLoading(true);

        try {
            const authToken = localStorage.getItem('authToken');
            const result = await searchChat(authToken, query);
            // Update the user's status locally in the table
            setOptions(result.data.data);
            setIsLoading(false);

        } catch (error) {
            toast.error(`${error.message}`);

            console.error('Error search request', error);
        }
    };

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    return (
        <Wrapper>
            <Sidebar/>
            <ContentContainer>
                <h2>Exports</h2>
                {error && <p>{error}</p>}
                <AsyncTypeahead
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey={(option) => `${option._id}: ${option.sender.fullName}-${option.receiver.fullName}`}
                    minLength={3} chr
                    onSearch={handleSearch}
                    options={options}
                    placeholder="Search for a conv..."
                    onChange={(e) => {
                        const chatInfo = e[0] ?? null;
                        setChatInfo(chatInfo);
                        if (chatInfo) {
                            handleExportChat(e[0]._id);
                        }
                    }}
                />
                <p>{chatInfo == null ? '' : `Exporting ${chatInfo._id}: ${chatInfo.sender.fullName}-${chatInfo.receiver.fullName}...`}</p>
            </ContentContainer>
        </Wrapper>
    );
}

export default Exports;
