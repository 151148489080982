// src/utilService.js

import countryList from "react-select-country-list";

export const getCountryByCode = (countryCode) => {
  const country = countryList().data.find((countryData) => {
    return countryData.value.toLowerCase() === countryCode.toLowerCase();
  });

  return typeof country === "undefined" ? countryCode : country.label;
};

export const profileUrl = (username) => {
  return `https://dialme.fans/${username}`;
}

