import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); 

function EditCategoryModal({ isOpen, onClose, onSave, categoryName }) {
  const [newName, setNewName] = useState(categoryName);

  const handleSave = () => {
    onSave(newName);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <h2>Edit Category</h2>
      <label>
        New Name:
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </label>
      <button onClick={handleSave}>Save</button>
      <button onClick={onClose}>Cancel</button>
    </Modal>
  );
}

export default EditCategoryModal;
