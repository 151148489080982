import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '../components/sideBar';
import {getSuggestedCreators} from '../apiService';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  button {
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
  }

  button:hover {
    background-color: #45a049;
  }
`;

function SuggestedCreators() {
  const [suggestedCreators, setSuggestedCreators] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch suggestedCreators when the component mounts
    const fetchSuggestedCreators = async () => {
      try {
        // Retrieve the token from local storage
        const authToken = localStorage.getItem('authToken');

        // Make API request to fetch suggestedCreators with the token
        const response = await getSuggestedCreators(authToken);

        // Assuming the API response contains a 'data' field with suggestedCreators
        setSuggestedCreators(response.data);
      } catch (error) {
        // Handle error
        setError('Failed to fetch suggestedCreators. Please try again.');
      }
    };

    fetchSuggestedCreators();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts


  return (
    <Wrapper>
      <Sidebar />
      <ContentContainer>
        <h2>Manage Suggested creators</h2>
        {error && <p>{error}</p>}
        <table>
          <thead>
            <tr>
              <th>Suggested creator ID</th>
              <th>Social media</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {suggestedCreators.map((suggestedCreator) => (
              <tr key={suggestedCreator._id}>
                <td>{suggestedCreator._id}</td>
                <td><a href={`https://instagram.com/${suggestedCreator.socialMedia}`} target={"_blank"}>{suggestedCreator.socialMedia}</a></td>
                <td>{suggestedCreator.createdAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentContainer>
    </Wrapper>
  );
}

export default SuggestedCreators;
