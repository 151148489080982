import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '../components/sideBar';
import {getRequestBecomeAmbassadors, hideUser, takeDecisionRequestBecomeAmbassador} from "../apiService";
import {toast} from "react-toastify";
import {getCountryByCode, profileUrl} from "../utilService";

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  button {
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
  }

  button.danger {
    cursor: pointer;
    background-color: #FF0000;
  }

  button:hover {
    background-color: #45a049;
  }

  button:hover.danger {
    cursor: pointer;
    background-color: #AA0000;
  }
`;

function ManageRequestBecomeAmbassadors() {
  const [requestBecomeAmbassadors, setRequestBecomeAmbassadors] = useState([]);
  //const [countries, setCountries] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch categories when the component mounts
    const fetchRequestBecomeAmbassadors = async () => {
      try {
        // Retrieve the token from local storage
        const authToken = localStorage.getItem('authToken');

        // Make API request to fetch categories with the token
        const response = await getRequestBecomeAmbassadors(authToken);

        // Assuming the API response contains a 'data' field with categories
        setRequestBecomeAmbassadors(response.data);
      } catch (error) {
        // Handle error
        setError('Failed to fetch request become ambassadors. Please try again.');
      }
    };

    fetchRequestBecomeAmbassadors();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts


  // Function to handle opening the edit modal
  const handleTakeDecisionClick = async (requestBecomeAmbassadorId, userId, isAccepted) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const result = await takeDecisionRequestBecomeAmbassador(authToken, requestBecomeAmbassadorId, userId, isAccepted);
      // Update the user's status locally in the table
      setRequestBecomeAmbassadors(requestBecomeAmbassadors.map(requestBecomeAmbassador =>
          requestBecomeAmbassador._id === requestBecomeAmbassadorId ? {
            ...requestBecomeAmbassador,
            status: isAccepted ? 'accepted' : 'declined'
          } : requestBecomeAmbassador
      ));

      toast.success(`${result.data.data.message}`);
    } catch (error) {
      toast.error(`${error.message}`);

      console.error('Error accepting request', error);
    }
  };
  // Function to handle saving changes from the edit modal
  const handleSaveEdit = (newName) => {
    // Perform the logic to update the category on the server
    // ...

    // Update the categories state with the new data
 /*   setRequestBecomeAmbassadors((prevRequestBecomeAmbassadors) =>
      prevRequestBecomeAmbassadors.map((requestBecomeAmbassador) =>
          requestBecomeAmbassador._id === selectedRequestBecomeAmbassador._id
          ? { ...requestBecomeAmbassador, name: newName }
          : requestBecomeAmbassador
      )
    );*/
  };

  return (
    <Wrapper>
      <Sidebar />
      <ContentContainer>
        <h2>Manage Request Become ambassadors</h2>
        {error && <p>{error}</p>}
        <table>
          <thead>
            <tr>
              <th>Created at</th>
              <th>Name</th>
              <th>Email</th>
              <th>Social media</th>
              <th>Promote content</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requestBecomeAmbassadors.map((requestBecomeAmbassador) => (
                <tr key={requestBecomeAmbassador._id}>
                  <td>{requestBecomeAmbassador.createdAt}</td>
                  <td><a target="_blank"
                         href={profileUrl(requestBecomeAmbassador.user.username)}>{requestBecomeAmbassador.name}</a>
                  </td>
                  <td>{requestBecomeAmbassador.email}</td>
                  <td><a href={requestBecomeAmbassador.socialMediaLink}>{requestBecomeAmbassador.socialMediaLink}</a></td>
                  <td>{requestBecomeAmbassador.promoteContent}</td>
                  <td>
                    {
                      requestBecomeAmbassador.status === 'pending' ? (
                          <>
                            <button
                              onClick={() => handleTakeDecisionClick(requestBecomeAmbassador._id, requestBecomeAmbassador.user, true)}>Accept
                            </button>
                            <button className={"danger"}
                                    onClick={() => handleTakeDecisionClick(requestBecomeAmbassador._id, requestBecomeAmbassador.user, false)}>Reject
                            </button>
                          </>
                      ) : null
                    }
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      </ContentContainer>
    </Wrapper>
  );
}

export default ManageRequestBecomeAmbassadors;
