import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { login } from '../apiService';
import { toast } from 'react-toastify';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoginForm = styled.form`
  text-align: center;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  box-sizing: border-box;
`;

const Button = styled.button`
  background: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
`;

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
  
    // Basic form validation
    if (!email.trim() || !password.trim()) {
      setError('Please enter both email and password.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const response = await login(email, password);
  
      // Assuming the API response contains a 'token' field
      const authToken = response.data.token;
  
      // Store the token in local storage
      localStorage.setItem('authToken', authToken);
  
      // Assuming the API response contains a 'name' field
      const userName = response.name;
  
      // Show success notification
      toast.success(`Login successful. Hello, ${userName}!`);
  
      // Navigate to the dashboard
      navigate('/dashboard');
    } catch (error) {
      // Show error notification
      toast.error('Login failed. Please check your credentials.');
      setError('Login failed. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <LoginForm onSubmit={handleLogin}>
        <h2>Welcome Dial Admin. Please Login</h2>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(''); // Clear error when user starts typing
          }}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(''); // Clear error when user starts typing
          }}
        />
        <Button type="submit" isLoading={isLoading}>
          {isLoading ? 'Loading...' : 'Login'}
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </LoginForm>
    </Wrapper>
  );
}

export default Login;
